<template>
    <div :style="moduleStyle">
        <slot />
    </div>
</template>

<script setup>
import {$getModuleColor} from "@/utils/appModules"

const props = defineProps({
    module: {
        type: String
    }
})

const hasAppModulesFeature = useFeature('appModules')

const moduleStyle = computed(() => {
    if(!hasAppModulesFeature) return {}
    const moduleColors = $getModuleColor(props.module)
    return {
        '--primary-color-50': getRGBValue((moduleColors && moduleColors['50']) ?? '#f0faff'),
        '--primary-color-100': getRGBValue((moduleColors && moduleColors['100']) ?? '#e0f4fe'),
        '--primary-color-200': getRGBValue((moduleColors && moduleColors['200']) ?? '#b9ecfe'),
        '--primary-color-300': getRGBValue((moduleColors && moduleColors['300']) ?? '#7bdffe'),
        '--primary-color-400': getRGBValue((moduleColors && moduleColors['400']) ?? '#35cefb'),
        '--primary-color-500': getRGBValue((moduleColors && moduleColors['500']) ?? '#0bbaee'),
        '--primary-color-600': getRGBValue((moduleColors && moduleColors['600']) ?? '#0095ca'),
        '--primary-color-700': getRGBValue((moduleColors && moduleColors['700']) ?? '#0177a3'),
        '--primary-color-800': getRGBValue((moduleColors && moduleColors['800']) ?? '#056587'),
        '--primary-color-900': getRGBValue((moduleColors && moduleColors['900']) ?? '#0b526f'),
        '--primary-color-950': getRGBValue((moduleColors && moduleColors['950']) ?? '#07344a')
    }
})

function hexToRgb(rgbOrHex) {
    const hex = rgbOrHex.replace('#', '')
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return `${r} ${g} ${b}`
}

function getRGBValue(rgbOrHex) {
    if (rgbOrHex.includes(' ')) {
        return rgbOrHex
    }
    return hexToRgb(rgbOrHex)
}


</script>
